import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Pagination, Input, Tooltip, Spin } from 'antd';
import { columns } from './constants';
import { DataType, Keys } from './types';
import {
  CheckOutlined,
  CloseOutlined,
  CheckCircleOutlined,
  StopOutlined,
  DeleteOutlined,
  RightCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/ru';
import cls from './index.module.scss';
import { getKeys, patchActive, deliteKey } from './api';
import { Layout } from '../../features/layout';

export const KeysPage = () => {
  const [client, setClient] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [keys, setKeys] = useState<Keys | undefined>(undefined);
  const [modified, setModified] = useState(false);

  moment.locale('ru');

  useEffect(() => {
    const data = {
      filter: client,
      skip: pageSize * (page - 1),
      limit: pageSize,
    };
    getKeys(data)
      .then((resp) => {
        setKeys(resp.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [client, page, pageSize, modified]);

  const setActive = (path: number, val?: boolean) => {
    patchActive({ id: path, data: { is_active: val } })
      .then(() => setModified(!modified))
      .catch((e) => console.log(e));
  };
  const setWrite = (path: number, write?: boolean) => {
    patchActive({ id: path, data: { can_write: write } })
      .then(() => setModified(!modified))
      .catch((e) => console.log(e));
  };

  const delite = (id: number) => {
    deliteKey(id)
      .then(() => setModified(!modified))
      .catch((e) => console.log(e));
  };

  const renderAction = (is_active: boolean, id: number) => {
    if (is_active)
      return (
        <Tooltip title="Деактивировать">
          <StopOutlined onClick={() => setActive(id, false)} />
        </Tooltip>
      );
    return (
      <Tooltip title="Активировать">
        <CheckCircleOutlined onClick={() => setActive(id, true)} />
      </Tooltip>
    );
  };

  const renderWrite = (can_write: boolean, id: number) => {
    if (can_write)
      return (
        <Tooltip title="Запретить редактирование">
          <StopOutlined onClick={() => setWrite(id, false)} />
        </Tooltip>
      );
    return (
      <Tooltip title="Разрешить редактирование">
        <CheckCircleOutlined onClick={() => setWrite(id, true)} />
      </Tooltip>
    );
  };

  const formData = (inputData: Keys) => {
    const keys = inputData;
    return keys.credentials.map((key, index) => {
      const el: DataType = {};
      el.key = index;
      el.client_id = key.client_id;
      el.date = moment(key.expiration_date).format('LLL');
      el.is_active = key.is_active ? <CheckOutlined /> : <CloseOutlined />;
      el.can_write = key.can_write ? <CheckOutlined /> : <CloseOutlined />;
      el.actions = (
        <div className={cls.actions}>
          <Tooltip title="Подробнее">
            <Link to={`/key/${key.partners_app_credential_id}`}>
              <RightCircleOutlined />
            </Link>
          </Tooltip>
          {renderAction(key.is_active, key.partners_app_credential_id)}
          {renderWrite(key.can_write, key.partners_app_credential_id)}
          <Tooltip title="Удалить">
            <DeleteOutlined
              onClick={() => delite(key.partners_app_credential_id)}
            />
          </Tooltip>
        </div>
      );
      return el;
    });
  };

  return (
    <Layout>
      <div>
        <h1>Ключи</h1>
        <div className={cls.header}>
          <span>Всего ключей: {keys?.all_count}</span>{' '}
          <Input
            placeholder="Поиск по client_id"
            onChange={(e) => setClient(e.target.value)}
            className={cls.search}
          />
        </div>
        {keys ? (
          <div>
            <Table
              dataSource={formData(keys)}
              columns={columns}
              pagination={false}
            />
            <Pagination
              showSizeChanger
              onChange={(current, pageSize) => {
                setPage(current);
                setPageSize(pageSize);
              }}
              defaultPageSize={pageSize}
              total={Number(keys?.all_count)}
              className={cls.pagination}
            />
          </div>
        ) : (
          <div className={cls.spin}>
            <Spin size="large" />
          </div>
        )}
      </div>
    </Layout>
  );
};
