import axios from 'axios';
import { instance } from '../../../shared/api/base';
import { LoginData, User } from '../types/user';

export const login_api = (data: LoginData) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_BASE}api_user_auth/login`,
    data,
  });
};

export const getUser = () => {
  return instance({
    method: 'get',
    url: 'api_user_auth/user/me',
    headers: { Authorization: localStorage.getItem('access_token') },
  });
};

export const registration = (data: User) => {
  return instance({
    method: 'post',
    url: 'api_user_auth/api_user_registration',
    data,
  });
};
