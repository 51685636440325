import { instance } from '../../../shared/api/base';

interface DataType {
  app_name: string;
  app_about: string;
}

export const createApp = (user: number, data: DataType) => {
  return instance({
    method: 'post',
    url: `lk/admin/partners_app/${user}`,
    headers: { Authorization: localStorage.getItem('access_token') },
    data,
  });
};
