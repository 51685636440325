import { instance } from '../../shared/api/base';

interface Query {
  filter?: string;
  skip: number;
  limit: number;
}

interface patchActiveProps {
  id: number;
  data?: {
    is_active?: boolean;
    can_write?: boolean;
  };
}
export const getKeys = (data: Query) => {
  return instance({
    method: 'get',
    url: `lk/admin/partners_app_credential/list?${
      data.filter ? `client_id_filter=${data.filter}&` : ''
    }skip=${data.skip}&limit=${data.limit}`,
    headers: { Authorization: localStorage.getItem('access_token') },
  });
};

export const patchActive = (props: patchActiveProps) => {
  return instance({
    method: 'patch',
    url: `/lk/admin/partners_app_credential/${props.id}`,
    headers: { Authorization: localStorage.getItem('access_token') },
    data: props.data,
  });
};

export const deliteKey = (id: number) => {
  return instance({
    method: 'delete',
    url: `/lk/admin/partners_app_credential/${id}`,
    headers: { Authorization: localStorage.getItem('access_token') },
  });
};

export const getKey = (id: number) => {
  return instance({
    method: 'get',
    url: `/lk/admin/partners_app_credential/${id}`,
    headers: { Authorization: localStorage.getItem('access_token') },
  });
};

export const getAllowed = () => {
  return instance({
    method: 'get',
    url: '/lk/admin/active_partners_app_router/list',
    headers: { Authorization: localStorage.getItem('access_token') },
  });
};

export const saveAllowed = (id: number, data: number[]) => {
  return instance({
    method: 'post',
    url: `/lk/admin/partners_app_credential/${id}/active_partners_app_router/assign`,
    headers: { Authorization: localStorage.getItem('access_token') },
    data: { allowed_routers_ids: data },
  });
};
