import { instance } from '../../shared/api/base';

interface GetTokenProps {
  client_id: string;
  client_secret: string;
}
interface UploadProps {
  name: string;
  token: string;
  data: any;
}
export const getToken = (data: GetTokenProps) => {
  return instance({
    method: 'post',
    url: `app/token`,
    headers: { Authorization: localStorage.getItem('access_token') },
    data,
  });
};

export const getInfo = (token: string) => {
  return instance({
    method: 'get',
    url: `/app/partners_app_credential/me`,
    headers: { Authorization: token },
  });
};

export const uploadFile = ({ name, token, data }: UploadProps) => {
  return instance({
    method: 'post',
    url: `app/upload_file/${name}`,
    headers: {
      Authorization: localStorage.getItem('access_token'),
      'X-Auth-Token': token,
      'Content-Type': 'multipart/form-data',
    },
    data,
  });
};
