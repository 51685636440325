import type { TableProps } from 'antd';
import { DataType } from './types';

export const columns: TableProps<DataType>['columns'] = [
  {
    title: 'client_id',
    dataIndex: 'client_id',
  },
  {
    title: 'Активен',
    dataIndex: 'is_active',
  },
  {
    title: 'Ручная загрузка данных',
    dataIndex: 'can_write',
  },
  {
    title: 'Дата действия до',
    dataIndex: 'date',
  },
  {
    title: 'Действия',
    dataIndex: 'actions',
  },
];
