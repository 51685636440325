import { Link } from 'react-router-dom';
import cls from './index.module.scss';

export const Layout = ({ children }: any) => {
  return (
    <div className={cls.page}>
      <Navigation />
      <div className={cls.content}>{children}</div>
    </div>
  );
};

const Navigation = () => (
  <nav className={cls.nav}>
    <Link to={'/upload'}>Загрузка данных</Link>
    <Link to={'/users'}>Пользователи</Link>
    <Link to={'/keys'}>Ключи</Link>
    <Link to={'/apps'}>Приложения</Link>
  </nav>
);
