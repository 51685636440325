import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from './features/error-boundary';
import { App } from './app';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <BrowserRouter>
    <ErrorBoundary>
      <Suspense>
        <App />
      </Suspense>
    </ErrorBoundary>
  </BrowserRouter>
);
