import { Modal, Button } from 'antd';
import { Link } from 'react-router-dom';
import cls from './index.module.scss';
import { KeyInfo } from '../../user/create-app/type';

interface KeyInfoModalProps {
  open: boolean;
  setOpen: (arg: boolean) => void;
  keyInfo: KeyInfo;
}
export const KeyInfoModal = ({ open, setOpen, keyInfo }: KeyInfoModalProps) => {
  return (
    <Modal
      title="Информация о ключе"
      centered
      open={open}
      onCancel={() => setOpen(false)}
      footer={[
        <Button key="back" onClick={() => setOpen(false)}>
          Закрыть
        </Button>,
        <Link to={`/key/${keyInfo.partners_app_credential_id}`}>
          <Button key="submit" type="primary">
            Продолжить
          </Button>
        </Link>,
      ]}
    >
      <div className={cls.modal}>
        Внимание, скопируйте полученные client_id и original_secret и передайте владельцу приложения. Значение original_secret доступно только один раз после генерации ключа. После нажатия на кнопку Продолжить значение original_secret для данного client_id будет невозможно восстановить. <br />
        client_id: {keyInfo.client_id} <br />
        original_secret: {keyInfo.original_secret}
      </div>
    </Modal>
  );
};
