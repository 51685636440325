import { useState, useEffect } from 'react';
import { Table, Pagination, Input, Tooltip, notification, Spin } from 'antd';
import { KeyInfoModal } from '../../features/key/info-modal';
import { columns } from './constants';
import { DataType, Apps } from './types';
import { PlusSquareOutlined } from '@ant-design/icons';
import cls from './index.module.scss';
import { KeyInfo } from '../../features/user/create-app/type';
import { generateKey, getApps } from './api';
import { Layout } from '../../features/layout';

export const AppsPage = () => {
  const [api, contextHolder] = notification.useNotification();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [apps, setApps] = useState<Apps | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const [key, setKey] = useState<KeyInfo | undefined>(undefined);

  useEffect(() => {
    const data = {
      name: name,
      email: email,
      skip: pageSize * (page - 1),
      limit: pageSize,
    };
    getApps(data)
      .then((resp) => {
        setApps(resp.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [email, name, page, pageSize]);

  const generate = (id: number) => {
    generateKey(id)
      .then((resp) => {
        setKey(resp.data);
        setOpen(true);
      })
      .catch((e) =>
        api['error']({
          message: 'Ошибка',
          description: e.response.data.detail.ru,
        })
      );
  };

  const formData = (inputData: Apps) => {
    const apps = inputData;
    return apps.partners_apps.map((app, index) => {
      const el: DataType = {};
      el.key = index;
      el.app = app.app_name;
      el.desc = app.app_about;
      el.name = app.owner_user.user_name;
      el.surname = app.owner_user.user_surname;
      el.patronymic = app.owner_user.user_patronymic;
      el.email = app.owner_user.user_email;
      el.actions = (
        <Tooltip title="Сгенерировать новый ключ">
          <PlusSquareOutlined onClick={() => generate(app.partners_app_id)} />
        </Tooltip>
      );
      return el;
    });
  };

  return (
    <Layout>
      <div>
        {contextHolder}
        {key && <KeyInfoModal open={open} setOpen={setOpen} keyInfo={key} />}
        <h1>Приложения</h1>
        <div className={cls.header}>
          <span>Всего приложений: {apps?.all_count}</span>{' '}
          <Input
            placeholder="Поиск по e-mail владельца"
            onChange={(e) => setEmail(e.target.value)}
            className={cls.search}
          />
          <Input
            placeholder="Поиск по названию приложения"
            onChange={(e) => setName(e.target.value)}
            className={cls.search}
          />
        </div>
        {apps ? (
          <div>
            <Table
              dataSource={formData(apps)}
              columns={columns}
              pagination={false}
            />
            <Pagination
              showSizeChanger
              onChange={(current, pageSize) => {
                setPage(current);
                setPageSize(pageSize);
              }}
              defaultPageSize={pageSize}
              total={Number(apps?.all_count)}
              className={cls.pagination}
            />
          </div>
        ) : (
          <div className={cls.spin}>
            <Spin size="large" />
          </div>
        )}
      </div>
    </Layout>
  );
};
