import { Route, Routes, Navigate, Outlet, Link } from 'react-router-dom';
import { NotFound } from './pages/404';
import { Login } from './features/login/ui/login';
import { UsersPage } from './pages/users';
import { KeysPage } from './pages/keys';
import { AppsPage } from './pages/apps';
import { KeyInfo } from './features/key/key-info';
import { UploadPage } from './pages/upload';
import { SupportPage } from './pages/support';
import './app.mpdule.scss';

export const App = () => {
  const PrivateWrapper = () => {
    const auth = !!localStorage.getItem('access_token');
    return auth ? <Outlet /> : <Navigate to="/login" />;
  };

  const SuperPrivateWrapper = () => {
    const auth = localStorage.getItem('is_verified');
    return auth === 'true' ? <Outlet /> : <Navigate to="/support" />;
  };

  return (
    <Routes>
      <Route element={<PrivateWrapper />}>
        <Route path="/upload" element={<UploadPage />} />
        <Route path="/support" element={<SupportPage />} />
        <Route element={<SuperPrivateWrapper />}>
          <Route path="/users" element={<UsersPage />} />
          <Route path="/keys" element={<KeysPage />} />
          <Route path="/apps" element={<AppsPage />} />
          <Route path="/key/:id" element={<KeyInfo />} />
        </Route>
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};
