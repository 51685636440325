import { instance } from '../../shared/api/base';

interface Query {
  filter?: string;
  skip: number;
  limit: number;
}

export const getUsers = (data: Query) => {
  return instance({
    method: 'get',
    url: `lk/admin/user/list?${
      data.filter ? `email_filter=${data.filter}&` : ''
    }skip=${data.skip}&limit=${data.limit}`,
    headers: { Authorization: localStorage.getItem('access_token') },
  });
};
