import { useState } from 'react';
import { Modal, Input, Button, notification } from 'antd';
import { KeyInfoModal } from '../../key/info-modal';
import { createApp } from './api';
import { KeyInfo } from './type';
import cls from './index.module.scss';

interface CreateModalProps {
  open: boolean;
  setOpen: (arg: boolean) => void;
  api_user_id: number;
}

export const CreateAppModal = ({
  open,
  setOpen,
  api_user_id,
}: CreateModalProps) => {
  const [api, contextHolder] = notification.useNotification();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(false); //open next modal about app` key
  const [key, setKey] = useState<KeyInfo | undefined>(undefined);

  const onSubmit = () => {
    setLoading(true);
    const data = {
      app_name: name,
      app_about: description,
    };
    createApp(api_user_id, data)
      .then((resp) => {
        console.log(resp);
        setKey(resp.data.credentials_info as KeyInfo);
        api['success']({
          message: 'Приложение успешно создано',
        });
        setLoading(false);
        setInfo(true);
      })
      .catch((e) => {
        api['error']({
          message: 'Ошибка',
          description: e.response.data.detail.ru,
        });
        setLoading(false);
      });

    setOpen(false);
  };

  return (
    <>
      {contextHolder}
      {key && <KeyInfoModal open={info} setOpen={setInfo} keyInfo={key} />}
      <Modal
        title="Создание приложения"
        centered
        open={open}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key="back" onClick={() => setOpen(false)}>
            Отменить
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={onSubmit}
          >
            Создать
          </Button>,
        ]}
      >
        <div className={cls.modal}>
          <Input
            placeholder="Название"
            onChange={(e) => setName(e.target.value)}
          />
          <Input.TextArea
            placeholder="Описание"
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </Modal>
    </>
  );
};
