import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Button, notification } from 'antd';
import { RegisterModal } from '../modal';
import { login_api, getUser } from '../../api/api';
import cls from './index.module.scss';

export const Login = () => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const navigate = useNavigate();

  const handleClickEnter = () => {
    setLoading(true);
    const data = { user_email: login, user_password: password };
    login_api(data)
      .then((resp) => {
        localStorage.setItem('access_token', resp.data.access_token);
        getUser()
          .then((resp) => {
            localStorage.setItem('is_verified', resp.data.is_platform_admin);
            navigate('/apps');
          })
          .catch((e) => console.log(e));
        setLoading(false);
      })
      .catch((e) => {
        api['error']({
          message: 'Ошибка',
          description: 'Неправильно введен логин или пароль',
        });
        setLoading(false);
      });
  };
  const isDesibled = () => {
    if (login && password) return false;
    return true;
  };

  return (
    <div className={cls.wrapper}>
      {contextHolder}
      <RegisterModal open={open} setOpen={setOpen} />
      <Input
        placeholder="Адрес электронной почты"
        onChange={(e) => setLogin(e.target.value)}
      />
      <Input.Password
        placeholder="Пароль"
        onChange={(e) => setPassword(e.target.value)}
      />
      <div className={cls.buttons}>
        <Button
          type="primary"
          disabled={isDesibled()}
          onClick={() => handleClickEnter()}
          loading={loading}
        >
          Вход
        </Button>

        <Button onClick={() => setOpen(true)}>Регистрация</Button>
      </div>
    </div>
  );
};
