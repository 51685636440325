import { useState, useEffect } from 'react';
import { Modal, Input, Button, Typography, notification } from 'antd';
import { User } from '../../types/user';
import { debounce } from '../../../../shared/utils/debounce';
import { registration } from '../../api/api';
import cls from './index.module.scss';

interface ModalProps {
  open: boolean;
  setOpen: (arg: boolean) => void;
}
const { Text } = Typography;

export const RegisterModal = ({ open, setOpen }: ModalProps) => {
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<User>({
    user_name: '',
    user_surname: '',
    user_patronymic: '',
    user_email: '',
    user_password: '',
  });
  const [replay, setReplay] = useState('');
  const [validate, setValidate] = useState(false);

  useEffect(() => {
    if (replay) {
      if (user.user_password !== replay) return setValidate(false);
      return setValidate(true);
    }
    return setValidate(true);
  }, [replay, user.user_password]);

  const onInputChange = debounce((e) => {
    setReplay(e.target.value);
  }, 1000);

  function validateEmail(email: string) {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return regex.test(email);
  }

  const isDisabled = () => {
    const isUserValid = Object.values(user).every(
      (value) => value.trim() !== ''
    );
    if (
      isUserValid &&
      replay &&
      validate &&
      user.user_password.length >= 8 &&
      validateEmail(user.user_email)
    )
      return false;
    return true;
  };

  const onSubmit = () => {
    setLoading(true);
    registration(user)
      .then((resp) => {
        api['success']({
          message: 'Регистрация прошла успешно',
        });
        setLoading(false);
        setOpen(false);
      })
      .catch((e) => {
        api['error']({
          message: 'Ошибка',
          description: e.response.data.detail.ru,
        });
        setLoading(false);
      });
  };

  return (
    <>
      {contextHolder}
      <Modal
        title="Регистрация"
        centered
        open={open}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key="back" onClick={() => setOpen(false)}>
            Отменить
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={onSubmit}
            disabled={isDisabled()}
          >
            Submit
          </Button>,
        ]}
      >
        <div className={cls.modal}>
          <Input
            placeholder="Имя"
            onChange={(e) => {
              setUser({ ...user, user_name: e.target.value });
            }}
          ></Input>
          <Input
            placeholder="Фамилия"
            onChange={(e) => {
              setUser({ ...user, user_surname: e.target.value });
            }}
          ></Input>
          <Input
            placeholder="Отчество"
            onChange={(e) => {
              setUser({ ...user, user_patronymic: e.target.value });
            }}
          ></Input>
          <Input
            placeholder="Адрес электронной почты"
            onChange={(e) => {
              setUser({ ...user, user_email: e.target.value });
            }}
          ></Input>
          Например: example@example.com
          <Input.Password
            placeholder="Пароль"
            onChange={(e) => {
              setUser({ ...user, user_password: e.target.value });
            }}
          ></Input.Password>
          Пароль должен содержать не менее 8 символов
          <Input.Password
            placeholder="Повторите пароль"
            onChange={onInputChange}
          ></Input.Password>
          {!validate && <Text type="danger">Пароли должны совпадать</Text>}
        </div>
      </Modal>
    </>
  );
};
