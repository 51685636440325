import { instance } from '../../shared/api/base';

interface Query {
  email?: string;
  name?: string;
  skip: number;
  limit: number;
}

export const getApps = (data: Query) => {
  return instance({
    method: 'get',
    url: `lk/admin/partners_app/list?${
      data.name ? `app_name_filter=${data.name}&` : ''
    }${data.email ? `owner_email_filter=${data.email}&` : ''}skip=${
      data.skip
    }&limit=${data.limit}`,
    headers: { Authorization: localStorage.getItem('access_token') },
  });
};

export const generateKey = (id: number) => {
  return instance({
    method: 'post',
    url: `/lk/admin/partners_app/${id}/partners_app_credential/generate`,
    headers: { Authorization: localStorage.getItem('access_token') },
  });
};
