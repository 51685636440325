import { Link } from 'react-router-dom';
import { Button, Result } from 'antd';

export const NotFound = () => (
  <Result
    status="404"
    title="404"
    subTitle="Старница не существует"
    extra={
      <Link to={'/apps'}>
        <Button type="primary">Вернуться</Button>
      </Link>
    }
  />
);
