import { useState, useEffect } from 'react';
import { Table, Pagination, Input, Tooltip, Spin } from 'antd';
import { columns } from './constants';
import { CreateAppModal } from '../../features/user/create-app';
import { DataType, Users } from './types';
import { getUsers } from './api';
import {
  CheckOutlined,
  CloseOutlined,
  PlusSquareOutlined,
} from '@ant-design/icons';
import { Layout } from '../../features/layout';
import cls from './index.module.scss';

export const UsersPage = () => {
  const [email, setEmail] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [users, setUsers] = useState<Users | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState(0);

  useEffect(() => {
    const data = {
      filter: email,
      skip: pageSize * (page - 1),
      limit: pageSize,
    };
    getUsers(data)
      .then((resp) => {
        setUsers(resp.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [email, page, pageSize]);

  const formData = (inputData: Users) => {
    const users = inputData;

    return users.api_users.map((user, index) => {
      const el: DataType = {};
      el.key = index;
      el.surname = user.user_surname;
      el.name = user.user_name;
      el.patronymic = user.user_patronymic;
      el.email = user.user_email;
      el.verified = user.is_verified ? <CheckOutlined /> : <CloseOutlined />;
      el.admin = user.is_platform_admin ? <CheckOutlined /> : <CloseOutlined />;
      el.actions = (
        <Tooltip title="Создание приложения">
          <PlusSquareOutlined
            onClick={() => {
              setOpen(true);
              setUserId(user.api_user_id);
            }}
          />
        </Tooltip>
      );
      return el;
    });
  };

  return (
    <Layout>
      <div className={cls.page}>
        <CreateAppModal open={open} setOpen={setOpen} api_user_id={userId} />
        <h1>Пользователи</h1>
        <div className={cls.header}>
          <span>Всего пользователей: {users?.all_count}</span>{' '}
          <Input
            placeholder="Поиск по e-mail"
            onChange={(e) => setEmail(e.target.value)}
            className={cls.search}
          />
        </div>

        {users ? (
          <div>
            <Table
              dataSource={formData(users)}
              columns={columns}
              pagination={false}
            />
            <Pagination
              showSizeChanger
              onChange={(current, pageSize) => {
                setPage(current);
                setPageSize(pageSize);
              }}
              defaultPageSize={pageSize}
              total={Number(users?.all_count)}
              className={cls.pagination}
            />
          </div>
        ) : (
          <div className={cls.spin}>
            <Spin size="large" />
          </div>
        )}
      </div>
    </Layout>
  );
};
