import { Layout } from '../../features/layout';
import cls from './index.module.scss';

export const SupportPage = () => {
  return (
    <Layout>
      <div className={cls.content}>
        Для предоставления доступа ко всем страницам обратитесь в службу
        поддержки
      </div>
    </Layout>
  );
};
