import type { TableProps } from 'antd';
import { DataType } from './types';

export const columns: TableProps<DataType>['columns'] = [
  {
    title: 'Название приложения',
    dataIndex: 'app',
  },
  {
    title: 'Описание',
    dataIndex: 'desc',
  },
  {
    title: 'Фамилия',
    dataIndex: 'surname',
  },
  {
    title: 'Имя',
    dataIndex: 'name',
  },
  {
    title: 'Отчество',
    dataIndex: 'patronymic',
  },
  {
    title: 'Почта',
    dataIndex: 'email',
  },
  {
    title: 'Действия',
    dataIndex: 'actions',
  },
];
