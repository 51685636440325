import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Button, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  deliteKey,
  patchActive,
  getKey,
  getAllowed,
  saveAllowed,
} from '../../../pages/keys/api';
import { Layout } from '../../layout';
import cls from './index.module.scss';
import { Key, AllowedSchema } from '../../../pages/keys/types';

interface SwaggerElProps {
  el: AllowedSchema;
  status: boolean;
  routers: number[];
  setRouters: (arg: number[]) => void;
}

const SwaggerEl = ({ el, status, routers, setRouters }: SwaggerElProps) => {
  const getColor = () => {
    if (el.method === 'GET') return cls.get;
    if (el.method === 'POST') return cls.post;
    if (el.method === 'DELETE') return cls.delete;
  };
  const add = () => {
    setRouters([...routers, el.active_partners_app_router_id]);
  };
  const remove = () => {
    const arr = routers.filter((r) => r !== el.active_partners_app_router_id);
    setRouters(arr);
  };
  return (
    <div className={cls.block}>
      <span>
        {status ? (
          <Tooltip title="Запретить">
            <CloseOutlined onClick={remove} />
          </Tooltip>
        ) : (
          <Tooltip title="Разрешить">
            <CheckOutlined onClick={add} />
          </Tooltip>
        )}
      </span>
      <div className={getColor()}>
        <span>{el.method}</span>
        <b>{el.path}</b>
        {el.name}
      </div>
    </div>
  );
};

export const KeyInfo = () => {
  const id = useParams();
  const navigate = useNavigate();
  const [key, setKey] = useState<Key | undefined>(undefined);
  const [allowed, setAllowed] = useState<AllowedSchema[] | undefined>(
    undefined
  );
  const [routers, setRouters] = useState<number[]>([]);

  useEffect(() => {
    getKey(Number(id.id))
      .then((resp) => {
        setKey(resp.data);
        setRouters(resp.data.allowed_routers_ids);
      })
      .catch((e) => console.log(e));
    getAllowed()
      .then((resp) => setAllowed(resp.data.allowed_routers))
      .catch((e) => console.log(e));
  }, []);

  const setActive = (val?: boolean) => {
    patchActive({ id: Number(id.id), data: { is_active: val } })
      .then(() => {
        let newkey = {
          ...key,
          credential_info: {
            ...key?.credential_info,
            is_active: val,
          },
        };
        setKey(newkey as Key);
      })
      .catch((e) => console.log(e));
  };

  const setWrite = (write?: boolean) => {
    patchActive({ id: Number(id.id), data: { can_write: write } })
      .then(() => {
        let newkey = {
          ...key,
          credential_info: {
            ...key?.credential_info,
            can_write: write,
          },
        };
        setKey(newkey as Key);
      })
      .catch((e) => console.log(e));
  };

  const delite = (id: number) => {
    deliteKey(id)
      .then(() => navigate('/keys'))
      .catch((e) => console.log(e));
  };

  const onSave = () => {
    saveAllowed(Number(id.id), routers)
      .then((resp) => setRouters(resp.data.allowed_routers_ids))
      .catch((e) => console.log(e));
  };

  return (
    <Layout>
      <div>
        <h3>Информация о ключе:</h3>
        <div className={cls.buttons}>
          <Button
            onClick={() => {
              setActive(key?.credential_info.is_active ? false : true);
            }}
          >
            {key?.credential_info.is_active ? 'Деактивировать' : 'Активировать'}
          </Button>
          <Button
            onClick={() => {
              setWrite(key?.credential_info.can_write ? false : true);
            }}
          >
            {key?.credential_info.can_write
              ? 'Запретить ручное редактирование'
              : 'Разрешить ручное редактирование'}
          </Button>
          <Button onClick={() => delite(Number(id.id))}>Удалить</Button>
        </div>
        <div>
          client_id: {key?.credential_info.client_id} <br />
          Активен:
          {key?.credential_info.is_active ? (
            <CheckOutlined />
          ) : (
            <CloseOutlined />
          )}
          <br />
          Годен до: {key?.credential_info.expiration_date}
        </div>
      </div>
      <div>
        <h3>Информация о приложении:</h3>
        <div>
          Название приложения: {key?.app_info.app_name}
          <br />
          Описание: {key?.app_info.app_about}
          <br />
          <h4>Владелец:</h4>
          Фамалия: {key?.app_info.owner_user.user_surname}
          <br />
          Имя: {key?.app_info.owner_user.user_name}
          <br />
          Отчество: {key?.app_info.owner_user.user_patronymic}
          <br />
          Почта: {key?.app_info.owner_user.user_email}
          <br />
        </div>
      </div>
      <div>
        <h3>Доступы:</h3>
        {allowed &&
          allowed?.map((el: AllowedSchema) => (
            <SwaggerEl
              el={el}
              routers={routers}
              setRouters={setRouters}
              status={routers.includes(el.active_partners_app_router_id)}
            />
          ))}
        <Button onClick={onSave}>Сохранить</Button>
      </div>
    </Layout>
  );
};
