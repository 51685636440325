import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Input, Button, Modal, notification, Spin, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { getInfo, getToken, uploadFile } from './api';
import { Layout } from '../../features/layout';
import cls from './index.module.scss';

interface ModalProps {
  open: boolean;
  setOpen: (arg: boolean) => void;
  setToken: (arg: string) => void;
}

const TokenModal = ({ open, setOpen, setToken }: ModalProps) => {
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ client_id: '', client_secret: '' });
  const onSubmit = () => {
    setLoading(true);
    getToken(data)
      .then((resp) => {
        setToken(resp.data.access_token);
        setOpen(false);
      })
      .catch((e) =>
        api['error']({
          message: 'Ошибка',
          description: e.response.data.detail.ru,
        })
      )
      .finally(() => setLoading(false));
  };

  return (
    <>
      {contextHolder}
      <Modal
        title="Введите данные о приложении"
        centered
        open={open}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key="back" onClick={() => setOpen(false)}>
            Отменить
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={onSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <div className={cls.modal}>
          Чтобы продолжить, введите client_id и client_secret с правом на запись
          данных
          <Input
            placeholder="client_id"
            onChange={(e) => {
              setData({ ...data, client_id: e.target.value });
            }}
          />
          <Input
            placeholder="client_secret"
            onChange={(e) => {
              setData({ ...data, client_secret: e.target.value });
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export const UploadPage = () => {
  const [api, contextHolder] = notification.useNotification();
  const [open, setOpen] = useState(true);
  const [token, setToken] = useState('');
  const [canWrite, setCanWrite] = useState(false);
  const [id, setId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [file, setFile] = useState<any>(undefined);

  useEffect(() => {
    if (token) {
      setLoading(true);
      getInfo(token)
        .then((resp) => {
          setCanWrite(resp.data.credential_info.can_write);
          setId(resp.data.credential_info.partners_app_credential_id);
        })
        .catch((e) =>
          api['error']({
            message: 'Ошибка',
            description: e.response.data.detail.ru,
          })
        )
        .finally(() => setLoading(false));
    }
  }, [token]);

  const onSubmit = () => {
    const formData = new FormData();
    formData.append('file', file);
    uploadFile({ name: name, token: token, data: formData })
      .then((resp) => {
        api['success']({
          message: 'Файл успешно загружен',
        });
      })
      .catch((e) =>
        api['error']({
          message: 'Ошибка',
          description: e.response.data.detail.ru,
        })
      )
      .finally(() => setLoading(false));
  };

  return (
    <Layout>
      {contextHolder}
      <TokenModal open={open} setOpen={setOpen} setToken={setToken} />
      {token &&
        (loading ? (
          <Spin size="large" className={cls.content} />
        ) : canWrite ? (
          <div className={cls.content}>
            <a href="/">Документация и примеры</a>
            <Input
              placeholder="Название AVRO схемы (можно узнать у администратора портала)"
              onChange={(e) => setName(e.target.value)}
            />
            <Upload
              listType="picture"
              accept=".csv, .xls, .xlsx"
              beforeUpload={(file) => {
                setFile(file);
                return false;
              }}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Прикрепить файл</Button>
            </Upload>
            <Button onClick={onSubmit}>Загрузить</Button>
          </div>
        ) : (
          <div className={cls.content}>
            Данное приложение недоступно для записи, вы можете изменить это на
            <Link to={`/key/${id}`}>странице ключа</Link>
          </div>
        ))}
      {!token && <Spin size="large" className={cls.content} />}
    </Layout>
  );
};
